<template>
    <div class="alipay-box">
        <van-image style="margin:0 auto;" width="3rem" height="3rem" :src="alipay" />
        <div style="margin:2rem 0;">支付成功,请在微信中查看</div>
        <img :src="alipaySuccess" style="width:4rem;height:4.2rem" />
        <div style="color:#000000;margin-top:2rem;font-size:1rem">
            <div style="font-size:0.88rem;margin-bottom:1rem">您的订单号</div>
            <div>{{orderno}}</div>
        </div>
    </div>
</template>
<script>
import alipay from "@/assets/image/aipay.png"
import alipaySuccess from "@/assets/image/alipaySuccess.png"
import { getUrlKey } from "@/libs/utils"
export default {
    name:"alipay-success",
    data(){
        return{
            alipaySuccess:alipaySuccess,
            alipay:alipay,
            orderno:"",
        }
    },
    mounted() {
    // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0,0);
        this.orderno = getUrlKey("orderno")
    }
}
</script>
<style scoped>
    .alipay-box{
        padding:0 5%;
        position: fixed;
        width: 90%;
        min-height: 100%;
        background: #f7f7f7;
        padding-top: 4rem;
        text-align: center;
        font-size: 0.88rem;
        color: #999999;
    }
</style>